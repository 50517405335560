import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { ApolloProvider } from "@apollo/react-hooks";
import client from "./utils/graphql.utils";
import TagManager from "react-gtm-module";

const googleTagTrackingId = "GTM-XCFD0XL3D9";
const tagManagerArgs = {
  gtmId: googleTagTrackingId,
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
);
