import { InventoryPricingType } from "./../interfaces/inventory.interface";
import { Inventory, InventoryPrice } from "../interfaces/inventory.interface";

export const PIECES = "PIECES";
export const PACK = "PACK";
export const VARIATION = "VARIATION";
export const NON_TRACKABLE = "NON_TRACKABLE";
export const PIECES_AND_PACK = "PIECES_AND_PACK";

export const getInventoryPrice = (
  inventory?: Inventory | InventoryPrice,
  inventoryType?: string,
  variationId?: string,
): InventoryPricingType | null => {
  if (
    ![PIECES, PACK, VARIATION, NON_TRACKABLE, PIECES_AND_PACK].includes(inventoryType as string)
  ) {
    return null;
  }

  if (!inventory || !inventoryType) {
    return null;
  }

  const inventoryPrice = (
    "InventoryPrice" in inventory ? inventory.InventoryPrice : inventory
  ) as InventoryPrice;

  if (inventoryType === VARIATION) {
    return inventoryPrice.VARIATION.find((variation: any) => variation.variationId === variationId)
      ?.price as InventoryPricingType;
  }

  return (inventoryPrice as any)[inventoryType] as InventoryPricingType;
};

export const getNonTrackablePrice = (inventory?: Inventory, inventoryType?: string): any => {
  if (inventory?.NonTrackableItem === null || inventory?.trackable) {
    return null;
  }

  return inventory?.NonTrackableItem as any as InventoryPricingType;
};
