export const Colors = {
	primaryColor: "#FFA412",
	secondaryColor: "#F75151",
	primaryGrey: "#4F4F4F",
	body: "#2F2F2F",
	white: "#FFFFFF",
	black: "#130F26",
	transparentBlack: "#00000014",
	blackLight: "#607087",
	lightBlue: "#8196B3",
	darkBlue: "#2B3C54",
	backgroundColor: "#E5E5E5",
	lightGrey: "#828282",
	inputBorderColor: "#C7C7C7",
	green: "#8FD597",
	placeholderColor: "#B9B9B9",
	offWhite: "#F6F8FB",
	overlayColor: "#607087b3",
	inActiveTab: "#F5F5F5",
	grey4: "#BDBDBD",
	buttonHover: "#FEEBCF",
	onlineGreen: "#6CFF7C",
	red: "#FF0000",
	lightRed: "#FCE9E9",
	lightOrange: "#fff8ee",
	darkGreen: "#219653",
	blue: "#1976D2",
	faintBlue: "#E6F1FC",
	lightGreen: "#DBF9E8",
	skyBlue: "#E4EBF4",
	grey: "#9EA8B7",
};

export const FontSizes = {
	titleFont: "24px",
	bigFont: "22px",
	detailsFontSize: "14px",
	descriptionFontSize: "12px",
	primaryFontSize: "16px",
	secondaryFontSize: "18px",
	headingFont: "28px",
};

export const FontFamily = {
	primaryFont: "Roboto",
	secondaryFont: "Helvetica",
};

export const BoxShadow = "4px 4px 30px rgba(24, 88, 177, 0.5)";
export const SmallerBoxShadow = "4px 4px 20px rgba(24, 88, 177, 0.2)";
