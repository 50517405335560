import styled from "styled-components";
import { Colors } from "../../GlobalStyles/theme";
const { grey } = Colors;

export const CategoryContainer = styled.div<{ height?: string }>`
	display: flex;
	gap: 10px;
	height: 100%;
	height: ${({ height }) => height ?? "100%"};
	width: 100%;
	overflow-x: scroll;
	margin-bottom: 0;
	margin: 0;

	::-webkit-scrollbar {
		background-color: #f6f8fb;
		height: 2px;
	}

	::-webkit-scrollbar-thumb {
		background-color: #ffbe62;
		border-radius: 4px;
	}
`;
export const Category = styled.p<{ active?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	width: auto;
	padding: 5px 15px;
	margin: 5px 0;
	border-radius: 1rem;
	font-size: 0.8rem;
	white-space: nowrap;
	cursor: pointer;
	color: ${({ active }) => (active ? "white" : grey)};
	background-color: ${({ active }) => (active ? "#130F26" : "#F6F8FB")};
`;
