import { Button, Flex, Span, Text } from "../../GlobalStyles/CustomizableGlobal.style";
import { Colors } from "../../GlobalStyles/theme";
import Nav from "../../Components/Nav";
import formatPrice from "../../utils/formatPrice.utils";
import { GET_OFFLINE_MEMBER_ORDERS_TYPE, IOrderItem } from "../../interfaces/orders.interface";
import { Image } from "../home/home.styles";
import { formatImageUrl } from "../../utils/formatImage.utils";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { GET_OFFLINE_MEMBER_ORDERS } from "../../schemas/schemas";
import { getShopId, getUniqueId } from "../../utils/getIds.utils";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import BottomNavbar from "../../Components/BottomNav";
import { Container, OrderContainer } from "../orders/orders.styles";
import { Loader } from "../../Components/LoaderComponent/LoaderComponent";
import Error from "../../Components/ErrorComponent";
import CaretRight from "../../assets/caret-right.svg";
import Meal from "../../assets/meals-grey.svg";
import Order from "../order/order";

const OrderItems = () => {
  const {
    grey,
    primaryGrey,
    black,
    primaryColor,
    skyBlue,
    blackLight,
    lightRed,
    lightOrange,
    red,
    blue,
    lightGreen,
    darkGreen,
  } = Colors;
  const [orderItems, setOrderItems] = useState<[IOrderItem] | undefined>();
  const [totalAmount, setTotalAmount] = useState<number | undefined>(0);
  const [showCard, setShowCard] = useState<boolean>(false);
  const [item, setItem] = useState<IOrderItem>();
  const [isParam, setIsParam] = useState<boolean>(false);

  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);
  const itemParam = query.get("item");
  const orderNumber = query.get("ordernumber");
  const id = query.get("id");

  useEffect(() => {
    if (itemParam) setIsParam(true);
  }, [itemParam, query]);

  window.onpopstate = () => {
    !isParam && navigate("/order-items");
  };

  const { data, loading, error } = useQuery<GET_OFFLINE_MEMBER_ORDERS_TYPE>(
    GET_OFFLINE_MEMBER_ORDERS,
    {
      variables: {
        shopId: getShopId(),
        uniqueId: getUniqueId(),
        limit: 10,
      },
    },
  );

  useEffect(() => {
    if (data?.getOfflineMemberOrders) {
      const order = data.getOfflineMemberOrders.orders?.find(
        (singleOrder) => singleOrder.orderId === id,
      );
      setOrderItems(order?.OrderItems);
      setTotalAmount(order?.totalAmount);
    }
  }, [data, id]);

  const noOrder = () => {
    return (
      <Container alignItems="center" justifyContent="center">
        <Flex
          gap="1rem"
          direction="column"
          alignItems="center"
          justifyContent="center"
          width="60%"
          margin="10rem 0 0 0"
        >
          <Image width="100px" src={Meal} />
          <Span color={blackLight} fontWeight="600" fontSize="1.2rem">
            You have no orders yet
          </Span>
          <Span textAlign="center" color={blackLight} fontSize="0.8rem">
            The order(s) you placed will appear here for you to see
          </Span>
        </Flex>
      </Container>
    );
  };

  const getOrderDetails = (orderId: string) => {
    const order = data?.getOfflineMemberOrders.orders?.find(
      (singleOrder) => singleOrder.orderId === orderId,
    );
    return { time: order?.createdAt, comment: order?.comment };
  };

  const getStatus = (status: string) => {
    const statuses = [
      { label: "PENDING", bg: lightOrange, color: primaryColor },
      { label: "PROCESSING", bg: skyBlue, color: blue },
      { label: "PROCESSED", bg: skyBlue, color: blue },
      { label: "ON_DELIVERING", bg: skyBlue, color: blue },
      { label: "CANCELLED", bg: lightRed, color: red },
      { label: "DELIVERED", bg: lightGreen, color: darkGreen },
      { label: "COMPLETED", bg: lightGreen, color: darkGreen },
    ];
    const matchedStatus = statuses.find((i) => i.label === status);
    return (
      <Span status fontSize="0.8rem" background={matchedStatus?.bg} color={matchedStatus?.color}>
        {status && status === "ON_DELIVERING"
          ? "On Delivering"
          : status?.charAt(0).toUpperCase() + status?.slice(1).toLowerCase()}
      </Span>
    );
  };

  const OrderItem = ({
    imageUrl,
    name,
    quantity,
    price,
    status,
    total,
    orderId,
    onClick,
    index,
  }: any) => {
    return (
      <Flex
        width="100%"
        alignItems="flex-start"
        direction="column"
        margin="0"
        padding="0 1rem"
        onClick={onClick}
        bg={index % 2 === 0 ? "#F6F8FB" : "transparent"}
      >
        <Flex
          width="100%"
          alignItems="flex-start"
          direction="column"
          margin="1rem 0"
          bg="transparent"
        >
          <Flex width="100%" alignItems="center" bg="transparent">
            <Image width="35px" circular src={formatImageUrl(imageUrl)} alt="product image" />
            <Flex
              width="100%"
              alignItems="flex-start"
              direction="column"
              margin="0 1rem"
              bg="transparent"
            >
              <Span color={blackLight} fontSize="1rem" fontWeight="500">
                {name}
              </Span>

              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                bg="transparent"
              >
                <Span color={blackLight} fontSize="0.9rem">
                  {quantity} X {price}
                </Span>
                {status}
                <Span color={blackLight} fontSize="0.9rem">
                  {total}
                </Span>
              </Flex>
            </Flex>
            <Image width="10px" src={CaretRight} alt="caret-right" />
          </Flex>
          <Span color={grey} fontSize="0.8rem" style={{ alignSelf: "end" }}>
            {moment(getOrderDetails(orderId).time).format("MMMM Do, h:mm a")}
          </Span>
        </Flex>
      </Flex>
    );
  };

  if (error)
    return (
      <Flex padding="1rem" direction="column" width="100%">
        <Nav route="/orders" title={`Order ${orderNumber}`} />
        <Error message={error.message} />
      </Flex>
    );

  return (
    <div>
      {loading ? (
        <Flex alignItems="center" justifyContent="center" margin="50% 0 0 0" width="100%">
          <Loader width="80" />
        </Flex>
      ) : (
        <Container>
          {data?.getOfflineMemberOrders && data?.getOfflineMemberOrders.total < 1 ? (
            noOrder()
          ) : (
            <>
              <Flex width="100%" padding="1rem">
                <Nav route="/orders" title={`Order #${orderNumber}`} />
              </Flex>
              <div className="items-wrapper">
                <Flex width="100%" direction="column" padding="1rem 0">
                  {orderItems?.map((eachItem, index) => {
                    const imageUrl = eachItem?.Inventory?.Images?.[0]?.largeImageOnlineURL;
                    const price = formatPrice(eachItem.amount / eachItem.quantity);
                    const total = formatPrice(eachItem.amount);
                    const status = getStatus(eachItem?.Steps?.stepName);

                    const handleClick = () => {
                      setShowCard(true);
                      setItem(eachItem);
                      navigate({
                        pathname: "/order-items",
                        search: `?item=${id}&ordernumber=${orderNumber}`,
                      });
                    };

                    return (
                      <OrderItem
                        key={eachItem.orderItemId}
                        index={index}
                        imageUrl={imageUrl}
                        name={eachItem.inventoryName}
                        quantity={eachItem.quantity}
                        price={price}
                        status={status}
                        total={total}
                        orderId={eachItem.orderId}
                        onClick={handleClick}
                      />
                    );
                  })}

                  <Flex
                    width="100%"
                    direction="column"
                    gap="1rem"
                    padding="2rem 1rem 0 1rem"
                    margin="0 0 1rem 0"
                  >
                    <Flex width="100%" alignItems="flex-start" justifyContent="space-between">
                      <Span color={grey} fontSize="0.9rem">
                        Subtotal
                      </Span>
                      <Span color={primaryGrey} fontSize="0.9rem">
                        {formatPrice(totalAmount)}
                      </Span>
                    </Flex>
                    <Flex
                      width="100%"
                      margin="0 0 1rem 0"
                      alignItems="flex-start"
                      justifyContent="space-between"
                    >
                      <Span color={black}>Order Total</Span>
                      <Span color={black}>{formatPrice(totalAmount)}</Span>
                    </Flex>
                    <Button
                      disabled={true}
                      height="60px"
                      fontSize="1.2rem"
                      borderRadius="16px"
                      width="100%"
                    >
                      Pay Now
                    </Button>
                    <Text color="red" fontSize="1.2rem" textAlign="center" alignSelf="center">
                      <em>Coming Soon...</em>
                    </Text>
                  </Flex>
                </Flex>
              </div>
            </>
          )}
          {showCard && !!itemParam && (
            <OrderContainer>
              {<Order item={item} orderDetails={getOrderDetails} orderNumber={orderNumber} />}
            </OrderContainer>
          )}
        </Container>
      )}
      <BottomNavbar />
    </div>
  );
};

export default OrderItems;
