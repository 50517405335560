import { FunctionComponent } from "react";
import Back from "../../assets/back-icon.svg";
import { TopNav } from "./Nav.styles";
import { Colors } from "../../GlobalStyles/theme";
import { Flex, Text } from "../../GlobalStyles/CustomizableGlobal.style";
import { useNavigate } from "react-router-dom";
import { GET_SHOP_TYPE } from "../../interfaces/shop.interface";
import { useMutation } from "@apollo/client";
import { CALL_ATTENDANT } from "../../schemas/schemas";
import { getTagId } from "../../utils/getIds.utils";
import { useAppDispatch } from "../../store/redux-hook";
import { toggleSnackbarOpen } from "../../store/slices/snackbarSlice";
import Call from "../../assets/call.svg";

interface Props {
	title?: string;
	route: string;
	onClick?: (event: Event) => void;
	shop?: GET_SHOP_TYPE["getShop"];
}

const Nav: FunctionComponent<Props> = ({ title, route, shop }) => {
	const { primaryGrey } = Colors;
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [callAttendant] = useMutation<{ callAttendant: boolean }>(CALL_ATTENDANT, {});

	const handleCallAttendant = () => {
		callAttendant({
			variables: {
				tagId: getTagId(),
			},
		}).then((res) => {
			if (res.data?.callAttendant) {
				dispatch(
					toggleSnackbarOpen({ message: "You will soon be attended to...", color: "SUCCESS" })
				);
			}
		});
	};

	return (
		<TopNav>
			<Flex alignItems="center" justifyContent="space-between" width="100%">
				<button>
					<img src={Back} alt="go back" onClick={() => navigate(route)} />
					<Text color={primaryGrey} fontSize="1rem" fontWeight="700" margin="0px 0px 0px 10px">
						{title}
					</Text>
				</button>
				<button id="call" onClick={handleCallAttendant}>
					<img src={Call} style={{ marginRight: "5px" }} alt="call attendant" />
					Call Attendant
				</button>
			</Flex>
		</TopNav>
	);
};

export default Nav;
