import styled from "styled-components";
import { Colors } from "../../GlobalStyles/theme";

const { primaryColor } = Colors;
interface IContainer {
	outOfStock?: boolean;
}

export const CardWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 180px;
	border-radius: 1rem;
	flex-direction: column;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

	.image-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 65%;
		width: 100%;
		padding: 0px;
		border-bottom: 1px solid #e7ecfe;
		position: relative;
	}

	#out-of-stock {
		position: absolute;
		width: 100%;
		bottom: 0;
		height: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	#add-to-cart {
		position: absolute;
		top: 10px;
		right: 8%;
		width: 50px;
	}

	#total-qty {
		position: absolute;
		display: flex;
		top: -5px;
		right: -5px;
		width: 25px;
		height: 25px;
		border-radius: 50%;
		background-color: ${primaryColor};
		border: 3px solid white;
		align-items: center;
		justify-content: center;
		margin: 0;
		padding: 0;
		color: white;
	}
`;
export const Container = styled.div<IContainer>`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin: 1rem 0;
	margin-left: 0.2rem;
	width: 100%;
	opacity: ${({ outOfStock }) => (outOfStock ? 0.4 : 1)};
`;
