import React, { useState } from "react";
import { Button, CountButton, Flex, Span, Text } from "../../GlobalStyles/CustomizableGlobal.style";
import { Colors } from "../../GlobalStyles/theme";
import { ICart, selectedProductsProps } from "../../interfaces/cart.interface";
import { Inventory } from "../../interfaces/inventory.interface";
import { useAppDispatch } from "../../store/redux-hook";
import { addToCartVariation } from "../../store/slices/cartSlice";
import { toggleSnackbarOpen } from "../../store/slices/snackbarSlice";
import formatPrice from "../../utils/formatPrice.utils";

const { offWhite } = Colors;

interface IVariation {
  inventory?: Inventory;
  setPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Quantities {
  [key: string]: number;
}

const Variation: React.FC<IVariation> = ({ inventory, setPopup }) => {
  const variations = inventory?.Variations;
  const dispatch = useAppDispatch();

  const [quantities, setQuantities] = useState<Quantities>({});
  const [selectedProducts, setSelectedProducts] = useState<selectedProductsProps[]>([]);

  const getItems = () => {
    const items: ICart[] = selectedProducts.map((product) => {
      return {
        inventory: inventory,
        variationId: product.variationId,
        quantity: product.quantity,
        variationName: product.variationName,
        price: product.price,
        inventoryType: inventory?.inventoryType,
        productType: inventory?.inventoryType,
      };
    });
    return items;
  };

  const getVariationQty = (variantId: string) => {
    return inventory?.InventoryQuantity?.find(({ variationId }) => variationId === variantId)
      ?.quantity!;
  };
  const getTotalAmount = () => {
    return selectedProducts.reduce((acc, { price, quantity }) => acc + price * quantity, 0);
  };

  const getProduct = (id: string) => {
    return variations?.find(({ variationId }) => variationId === id)!;
  };

  const handleAddToCart = () => {
    setPopup(false);
    dispatch(addToCartVariation(getItems()));
    dispatch(
      toggleSnackbarOpen({
        message: `Variant(s) of ${inventory?.inventoryName} added to cart!`,
        color: "SUCCESS",
      }),
    );
  };

  const handleIncrease = (id: string) => {
    const count = (quantities[id] || 0) + 1;
    setQuantities({
      ...quantities,
      [id]: count,
    });

    if (count === 1) {
      setSelectedProducts([
        ...selectedProducts,
        { ...getProduct(id), quantity: count, price: getProduct(id).price, variationId: id },
      ]);
    } else if (count > 1) {
      setSelectedProducts((prevSelectedProducts) => {
        return prevSelectedProducts.map((selectedProduct) => {
          if (selectedProduct.variationId === id) {
            return { ...selectedProduct, quantity: count };
          }
          return selectedProduct;
        });
      });
    }
  };

  const handleDecrease = (id: string) => {
    const count = (quantities[id] || 0) - 1;
    setQuantities({
      ...quantities,
      [id]: Math.max(0, count),
    });
    if (count === 0) {
      setSelectedProducts(selectedProducts.filter(({ variationId }) => variationId !== id));
    } else if (count > 0) {
      setSelectedProducts((prevSelectedProducts) => {
        return prevSelectedProducts.map((selectedProduct) => {
          if (selectedProduct.variationId === id) {
            return { ...selectedProduct, quantity: count };
          }
          return selectedProduct;
        });
      });
    }
  };

  return (
    <Flex width="100%" direction="column" overflowY="scroll">
      <Flex width="100%" direction="column" overflowY="scroll" maxHeight="250px">
        {variations?.map((variation, i) => {
          const quantity = quantities[variation.variationId] || 0;

          return (
            <Flex
              key={variation.variationId}
              bg={i % 2 === 0 ? offWhite : "transparent"}
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              margin="0 0 1rem 0"
            >
              <Flex
                width="100%"
                height="70px"
                padding="1rem"
                justifyContent="space-between"
                bg="transparent"
                alignItems="center"
              >
                <Flex
                  width="100%"
                  bg="transparent"
                  direction="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Text fontSize="1rem">{variation.variationName}</Text>
                  <Text fontSize="1.2rem" fontWeight="700">
                    {formatPrice(variation.price)}
                  </Text>
                </Flex>
              </Flex>
              <Flex
                bg="transparent"
                justifyContent="space-between"
                alignItems="center"
                width="40%"
                margin="0 1rem 0 0"
              >
                <CountButton
                  disabled={quantity < 1}
                  height="35px"
                  borderRadius="4px"
                  padding="3px"
                  width="35px"
                  margin="0 5px"
                  fontSize="1.5rem"
                  onClick={() => handleDecrease(variation.variationId)}
                >
                  -
                </CountButton>
                <Span fontWeight="700"> {quantity}</Span>
                <CountButton
                  disabled={quantity >= getVariationQty(variation?.variationId)}
                  height="35px"
                  borderRadius="4px"
                  padding="3px"
                  width="35px"
                  margin="0 5px"
                  fontSize="1.5rem"
                  onClick={() => handleIncrease(variation.variationId)}
                >
                  +
                </CountButton>
              </Flex>
            </Flex>
          );
        })}
      </Flex>

      <Flex justifyContent="center" alignItems="center" width="100%" padding=" 0 1rem 1rem 1rem">
        <Button
          borderRadius="1rem"
          width="100%"
          onClick={handleAddToCart}
          disabled={getItems().length < 1}
        >
          Add {formatPrice(getTotalAmount())}
        </Button>
      </Flex>
    </Flex>
  );
};

export default Variation;
