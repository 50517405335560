import styled from "styled-components";

export const TopNav = styled.div`
	height: fit-content;
	width: 100%;
	padding: 3px 0px;
	justify-content: space-between;
	align-items: center;

	button {
		display: flex;
		align-items: center;
		background: none;
		border: none;
		padding: 0px;
	}
`;
