import styled from "styled-components";
import { Colors } from "../../GlobalStyles/theme";

const { blackLight } = Colors;

export const ErrorContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin: 2rem 10px;
`;

export const ErrorMessage = styled.h5`
	color: ${blackLight};
	font-size: 1em;
	text-align: center;
	margin: 1em 0;
	width: 100%;
`;
