import styled from "styled-components";
import { Colors } from "../../GlobalStyles/theme";

const { white, primaryColor } = Colors;

interface IDot {
	position: number;
	slideIndex: number;
}

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 250px;
	width: 250px;
`;
export const ProductImageCard = styled.div`
	/* margin: 0 auto; */
	align-items: center;
	position: relative;
	background: ${white};
	border-radius: 50%;
	height: 210px;
	width: 210px;
`;

export const DotContainer = styled.div`
	position: absolute;
	width: 100%;
	bottom: -20px;
	display: flex;
	justify-content: center;
	z-index: 2;
	padding-top: 2rem;
`;

export const Dot = styled.div`
	height: 10px;
	width: 10px;
	border-radius: 50%;
	border: 1px solid ${primaryColor};
	background: ${(props: IDot) => (props.position === props.slideIndex ? primaryColor : white)};
	margin: 5px;
`;
