import { useEffect } from "react";
import { Text, Flex, CheckBox } from "../../GlobalStyles/CustomizableGlobal.style";
import { Colors } from "../../GlobalStyles/theme";
import { Container, Image } from "./home.styles";
import CheckIcon from "../../../src/assets/check.svg";
import Call from "../../../src/assets/call.svg";
import { useState } from "react";
import BottomNavbar from "../../Components/BottomNav";
import Products from "../../Components/Products";
import { useMutation, useQuery } from "@apollo/client";
import { CALL_ATTENDANT, GET_SHOP_SCHEMA } from "../../schemas/schemas";
import { GET_SHOP_TYPE } from "../../interfaces/shop.interface";
import { getShopId, getTagId } from "../../utils/getIds.utils";
import { formatImageUrl } from "../../utils/formatImage.utils";
import { titleCase } from "title-case";
import { useDispatch } from "react-redux";
import { toggleSnackbarOpen } from "../../store/slices/snackbarSlice";

const Home = () => {
  const { black, primaryGrey } = Colors;
  const [isAvailableProducts, setIsAvailableProducts] = useState<boolean>(true);
  const [searchString, setSearchString] = useState<string>("");
  const [shop, setShop] = useState<GET_SHOP_TYPE["getShop"]>();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const { shopId, tagId } = params;

  const dispatch = useDispatch();

  useEffect(() => {
    if (shopId && tagId) {
      localStorage.setItem("orderShopId", shopId);
      localStorage.setItem("orderTagId", tagId);
    }
  }, [shopId, tagId]);

  const {
    data: shopData,
    loading,
    refetch,
  } = useQuery<GET_SHOP_TYPE>(GET_SHOP_SCHEMA, {
    fetchPolicy: "network-only",
    variables: {
      shopId,
      shopStatus: "KITCHEN_PUBLISHED",
    },
  });

  useEffect(() => {
    if (shopData) {
      setShop(shopData?.getShop);
    }
  }, [shopData]);

  useEffect(() => {
    if (shopId !== getShopId()) {
      refetch();
    }

    if (window.location.pathname === "/") {
      const newPathname = `?shopId=${getShopId()}&tagId=${getTagId()}`;
      window.history.replaceState(null, "", newPathname);
    }
  }, [refetch, shopId]);

  const [callAttendant] = useMutation<{ callAttendant: boolean }>(CALL_ATTENDANT, {});

  const handleCallAttendant = () => {
    callAttendant({
      variables: {
        tagId: getTagId(),
      },
    }).then((res) => {
      if (res.data?.callAttendant) {
        dispatch(
          toggleSnackbarOpen({ message: "You will soon be attended to...", color: "SUCCESS" }),
        );
      }
    });
  };

  const image = shop?.Images && shop?.Images[0]?.mediumImageOnlineURL;

  if ((!shopId || !tagId) && !loading)
    return (
      <Container>
        <Flex
          alignItems="center"
          justifyContent="center"
          direction="column"
          margin="4rem 0 0 0"
          gap="1rem"
        >
          <Text fontWeight="700" fontSize="6rem" margin="0" alignSelf="center" width="90%">
            oops!
          </Text>

          <Text
            fontWeight="400"
            fontSize="1.5rem"
            margin="0"
            alignSelf="center"
            width="90%"
            textAlign="center"
          >
            Shop not found, Please try again.
          </Text>
        </Flex>
      </Container>
    );

  return (
    <Container>
      <Flex
        style={{ gap: "1rem" }}
        alignItems="center"
        padding="0.5rem 1rem 1rem 1rem"
        maxWidth="576px"
        className="header"
      >
        <Image
          width="50px"
          height="50px"
          circular={true}
          className="shop-image"
          src={formatImageUrl(image)}
          alt=""
        />
        <Flex
          direction="column"
          height="45px"
          justifyContent="space-between"
          alignItems="flex-start"
          maxWidth="478px"
        >
          <Text color={black} fontWeight="700" margin="0">
            {shop?.shopName && titleCase(shop?.shopName)}
          </Text>
          <Flex alignItems="center" justifyContent="space-between">
            <button id="call" onClick={handleCallAttendant}>
              <img src={Call} style={{ marginRight: "5px" }} alt="call attendant" />
              Call Attendant
            </button>
            <Flex margin="0" width="60%%" alignItems="center" gap="0.5rem">
              <CheckBox
                checked={isAvailableProducts}
                onClick={() => setIsAvailableProducts(!isAvailableProducts)}
              >
                <img src={CheckIcon} alt="check icon" />
              </CheckBox>
              <label
                style={{ fontSize: "0.8rem", color: `${primaryGrey}` }}
                htmlFor="menus"
                onClick={() => setIsAvailableProducts(!isAvailableProducts)}
              >
                Available items only
              </label>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Products
        searchString={searchString}
        isAvailableProducts={isAvailableProducts}
        setSearchString={setSearchString}
      />
      <div className="bottom">
        <BottomNavbar />
      </div>
    </Container>
  );
};

export default Home;
