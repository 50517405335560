import styled, { keyframes } from "styled-components";

const revealAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ProductsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin-top: 4rem;
`;

export const Grid = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: 1rem;
	gap: 1rem;
	overflow-y: scroll;
	padding-bottom: 11rem;

	.products {
		flex: 1 0 150px;
		height: 190px;
		max-width: 48%;
		box-sizing: border-box;
		animation: ${revealAnimation} 0.5s ease-in-out;
	}

	.loader {
		display: flex;
		width: 100%;
		align-items: flex-start;
		justify-content: center;
		height: 100px;
		max-height: 100px;
		margin-top: 1rem;
	}

	#filter {
		display: flex;
		gap: 10px;
		flex-direction: column;
		width: 100%;
		align-items: flex-start;
		justify-content: center;
		height: 100px;
		max-height: 100px;
	}
	.no-product {
		display: flex;
		grid-column: 1 / -1;
		align-items: center;
		justify-content: center;
	}
`;
