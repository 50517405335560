import styled from "styled-components";

interface IContainer {
	alignItems?: string;
	justifyContent?: string;
}

export const Container = styled.div<IContainer>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	align-items: ${({ alignItems }) => alignItems || "unset"};
	justify-content: ${({ justifyContent }) => justifyContent || "unset"};
	height: 100vh;

	.items-wrapper,
	.wrapper {
		max-height: calc(100vh - 100px);
		overflow-y: scroll;
		padding-bottom: 4rem;
	}
`;

export const OrderContainer = styled.div`
	display: flex;
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 99;
	background-color: white;
	align-items: flex-start;
	justify-content: flex-start;
`;
export const OrdersContainer = styled.div`
	column-count: 2;
	column-gap: 1rem;
	padding: 1rem;
`;
export const OrderCard = styled.div`
	break-inside: avoid-column;
	margin-bottom: 1rem;

	display: flex;
	/* width: 48%; */
	padding: 0.7rem;
	/* flex-grow: 0 1; */
	border-radius: 12px;
	cursor: pointer;
	height: auto;
	background-color: #f6f8fb;
	flex-direction: column;
`;

export const Indicator = styled.div<{ color: string }>`
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: ${({ color }) => color || "unset"};
`;
