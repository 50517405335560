import { FunctionComponent } from "react";
import { Page } from "./Pagination.styles";

interface IProps {
	page: number;
	position?: string;
	marginBottom?: string;
	total?: number;
	limit: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
}

const Pagination: FunctionComponent<IProps> = ({ page, limit, total, setPage, position, marginBottom }) => {
	const lastPage = Math.ceil(total! && total / limit);

	const nextPage = () => {
		setPage(page + 1);
	};

	const prevPage = () => {
		setPage(page > 1 ? page - 1 : page);
	};

	return (
		<Page page={page} position={position} marginBottom={marginBottom}>
			<button disabled={page <= 1} className="prev" onClick={prevPage}>
				Prev
			</button>
			<button className="next" onClick={nextPage} disabled={page >= lastPage}>
				Next
			</button>
		</Page>
	);
};

export default Pagination;
