import styled from "styled-components";
import { Colors } from "../../GlobalStyles/theme";

const { white, primaryColor } = Colors;

export const Wrapper = styled.div`
	display: flex;
	border-radius: 20px 20px 0 0;
	height: 60px;
	padding: 1rem 2.5rem;
	background-color: ${white};
	box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 19px 2px;
	position: fixed;
	bottom: 0;
	gap: 1.5rem;
	width: 100%;
	max-width: 576px;
	justify-content: space-between;
	align-items: center;

	.span {
		display: flex;
		align-content: center;
		justify-content: center;
		position: absolute;
		height: 35px;
		width: 35px;
		border-radius: 50%;
		background-color: ${primaryColor};
		padding: 4px;
		border: 4px solid white;
		color: white;
		top: -20%;
	}
`;
