export const getShopId = () => {
	return localStorage.getItem("orderShopId");
};

export const getTagId = () => {
	return localStorage.getItem("orderTagId");
};

export const getUniqueId = () => {
	return localStorage.getItem("orderUniqueId");
};
