import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Button, CountButton, Flex, Span, Text } from "../../GlobalStyles/CustomizableGlobal.style";
import { Colors } from "../../GlobalStyles/theme";
import { BuyType, ICart } from "../../interfaces/cart.interface";
import { Inventory } from "../../interfaces/inventory.interface";
import { useAppDispatch, useAppSelector } from "../../store/redux-hook";
import { addToCart } from "../../store/slices/cartSlice";
import { toggleSnackbarOpen } from "../../store/slices/snackbarSlice";
import formatPrice from "../../utils/formatPrice.utils";
import { CheckBox } from "./AddToCart.styles";

interface IProp {
	inventory?: Inventory;
	quantity: number;
	handleQuantity: Function;
	setPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const PiecesAndPack: React.FC<IProp> = ({ inventory, setPopup, quantity, handleQuantity }) => {
	const dispatch = useAppDispatch();
	const [buyType, setBuyType] = useState<BuyType>(BuyType.PIECES);
	const price = inventory?.InventoryPrice[buyType].sellingPrice!;
	const { offWhite } = Colors;

	const { cart } = useAppSelector((state) => state.cart);

	const item: ICart = useMemo(() => {
		return {
			price,
			quantity,
			inventory,
			PiecesAndPackType: buyType,
			inventoryType: inventory?.inventoryType,
		};
	}, [inventory, price, quantity, buyType]);

	const cartItem = cart.find(
		(foo) =>
			foo.inventory?.inventoryId === item?.inventory?.inventoryId &&
			foo.PiecesAndPackType === item.PiecesAndPackType
	);

	const inCart = useCallback(() => {
		return cartItem?.inventoryType === item?.inventoryType;
	}, [cartItem?.inventoryType, item]);

	useEffect(() => {
		inCart();
	}, [inCart]);

	const handleAddToCart = useCallback(() => {
		dispatch(addToCart(item));
		setPopup(false);
		dispatch(
			toggleSnackbarOpen({
				message: `${item.inventory?.inventoryName} added to cart!`,
				color: "SUCCESS",
			})
		);
	}, [dispatch, item, setPopup]);

	return (
		<Flex width="100%" direction="column">
			<Flex
				key={inventory?.inventoryId}
				width="100%"
				alignItems="center"
				justifyContent="space-between">
				<Flex
					width="100%"
					height="auto"
					justifyContent="space-between"
					bg="transparent"
					direction="column"
					alignItems="center"
					margin="0 0 1rem 0">
					<Flex
						width="100%"
						bg="white"
						padding="1rem 1rem 0.5rem 1rem"
						direction="column"
						justifyContent="space-between"
						alignItems="flex-start"
						gap="0.5rem">
						<Text fontSize="1rem">{inventory?.inventoryName}</Text>
						<Text fontSize="1.2rem" fontWeight="700">
							{formatPrice(inventory?.InventoryPrice[buyType].sellingPrice!)}
						</Text>
					</Flex>
					<Flex
						width="100%"
						bg={offWhite}
						padding="1rem"
						direction="column"
						justifyContent="space-between"
						alignItems="flex-start"
						gap="1rem">
						<Flex
							width="100%"
							alignItems="center"
							justifyContent="space-between"
							bg="transparent">
							<Flex
								width="100%"
								alignItems="center"
								justifyContent="flex-start"
								bg="transparent"
								gap="1rem">
								{Object.values(BuyType).map((type, i) => (
									<CountButton
										key={type}
										height="30px"
										borderRadius="5px"
										padding="0 0 0 8px"
										width="100px"
										fontSize="1rem"
										alignItems="center"
										justifyContents="flex-start"
										border={`2px solid ${type === buyType ? "#219653" : "#9EA8B7"}`}
										color={type === buyType ? "#219653" : "#9EA8B7"}
										backgroundColor={type === buyType ? "#DBF9E8" : "transparent"}
										onClick={() => setBuyType(type as BuyType)}>
										<CheckBox
											radius="50%"
											htmlFor="toggle-pack"
											checked={type === buyType}>
											<span></span>
										</CheckBox>
										<input type="checkbox" id="toggle-pack" hidden />
										<p id="name">{type === "PIECES" ? "Pieces" : "Pack"}</p>
									</CountButton>
								))}
							</Flex>
							<Flex
								bg="transparent"
								justifyContent="space-between"
								alignItems="center"
								width="auto">
								<CountButton
									disabled={cartItem?.quantity! <= 1 ?? quantity <= 1}
									height="30px"
									width="30px"
									borderRadius="4px"
									padding="3px"
									fontSize="1.3rem"
									onClick={() => handleQuantity("dec", buyType)}>
									-
								</CountButton>
								<Span fontWeight="700" margin="0 0.8rem">
									{inCart() ? cartItem?.quantity : cartItem?.quantity ?? quantity}
								</Span>
								<CountButton
									disabled={
										cartItem?.quantity! >= inventory?.quantity! ??
										quantity >= inventory?.quantity!
									}
									height="30px"
									width="30px"
									borderRadius="4px"
									padding="3px"
									fontSize="1.5rem"
									onClick={() => handleQuantity("inc", buyType)}>
									+
								</CountButton>
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</Flex>

			<Flex justifyContent="center" alignItems="center" width="100%" padding=" 0 1rem 1rem 1rem">
				<Button
					borderRadius="1rem"
					width="100%"
					fontSize="1.2rem"
					onClick={handleAddToCart}
					disabled={inCart()}>
					{inCart() ? "Item already in cart -" : "Add"} {""}
					{formatPrice((cartItem?.quantity ?? quantity) * price)}
				</Button>
			</Flex>
		</Flex>
	);
};

export default PiecesAndPack;
