import { useMemo, useState } from "react";
import { FunctionComponent } from "react";
import { Flex, Span } from "../../GlobalStyles/CustomizableGlobal.style";
import { Image } from "../../pages/home/home.styles";
import { CardWrapper, Container } from "./ProductCard.styles";
import formatPrice from "../../utils/formatPrice.utils";
import { formatImageUrl } from "../../utils/formatImage.utils";
import { getInventoryPrice, getNonTrackablePrice } from "../../utils/inventory.utils";
import { Inventory } from "../../interfaces/inventory.interface";
import { useAppDispatch, useAppSelector } from "../../store/redux-hook";
import { ICart } from "../../interfaces/cart.interface";
import { addToCart, removeFromCart } from "../../store/slices/cartSlice";
import ToCart from "../../assets/add-to-cart.svg";
import Placeholder from "../../assets/image-placeholder.svg";
import { toggleSnackbarOpen } from "../../store/slices/snackbarSlice";

interface IProduct {
  setSelectedInventoryId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPopup?: Function;
  inventory: Inventory;
}

const ProductCard: FunctionComponent<IProduct> = ({
  setSelectedInventoryId,
  setPopup,
  inventory,
}) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>();
  const { cart } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();

  const productName = inventory?.inventoryName;
  const inventoryPrice = inventory?.InventoryPrice;
  const inventoryTypeProp = inventory?.inventoryType;
  const images = inventory?.Images;
  const quantity = inventory?.quantity;
  const isOutOfStock = inventory.trackable && (quantity ?? 0) <= 0;

  const price = useMemo(
    () => getInventoryPrice(inventoryPrice, inventoryTypeProp)?.sellingPrice,
    [inventoryTypeProp, inventoryPrice],
  );
  const nonTrackablePrice = useMemo(
    () => getNonTrackablePrice(inventory, inventoryTypeProp)?.sellingPrice,
    [inventoryTypeProp, inventory],
  );

  /* Checking if the item is already in the cart. */
  let totalQuantity;
  totalQuantity = cart.find((cartItem) => cartItem.inventory?.inventoryId === inventory.inventoryId)
    ?.quantity!;

  /* This is to get the total quantity of the variations of a product. */
  if (inventory.inventoryType === "VARIATION") {
    totalQuantity = (
      cart.filter((cartItem) => cartItem.inventory?.inventoryId === inventory.inventoryId) as any
    ).reduce((acc: any, item: { quantity: number }) => acc + item.quantity, 0);
  }

  /** Handles adding an item to the cart if it is not out of stock and sets a popup to true.*/
  const handleAddToCart = () => {
    if (!isOutOfStock) {
      setPopup && setPopup(true);
      setSelectedInventoryId(inventory?.inventoryId);
    }
  };

  /* Sorting the variations by price and getting the min and max price. */
  const sortedVariations = inventory.Variations.slice().sort((a, b) => a.price - b.price);
  const maxVarPrice = sortedVariations[sortedVariations.length - 1]?.price;
  const minVarPrice = sortedVariations[0]?.price;

  /* Getting the price of the inventory for Pieces and pack product. */
  const pieces = inventoryPrice.PIECES.sellingPrice!;
  const pack = inventoryPrice.PACK.sellingPrice!;

  const quickAdd = () => {
    const types = ["VARIATION", "PIECES_AND_PACK"];
    const inCart = cart.find((item) => item.inventory?.inventoryId === inventory.inventoryId);
    const item: ICart = {
      inventory,
      quantity: 1,
      price: inventory.trackable ? price! : nonTrackablePrice!,
      inventoryType: inventoryTypeProp,
    };

    if (!types.includes(inventoryTypeProp) && !inCart) {
      dispatch(addToCart(item));
      dispatch(
        toggleSnackbarOpen({
          message: `${item.inventory?.inventoryName} added to cart!`,
          color: "SUCCESS",
        }),
      );
    } else if (!types.includes(inventoryTypeProp) && inCart) {
      dispatch(removeFromCart({ id: item.inventory?.inventoryId }));
      dispatch(
        toggleSnackbarOpen({
          message: `${item.inventory?.inventoryName} removed from cart!`,
          color: "SUCCESS",
        }),
      );
    } else {
      handleAddToCart();
    }
  };

  const handleClick = (event: any) => {
    event.stopPropagation();
    quickAdd();
  };

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  return (
    <Container outOfStock={isOutOfStock} onClick={handleAddToCart}>
      <CardWrapper>
        <div className="image-wrapper">
          <Image
            border={true}
            width="60%"
            maxWidth="106px"
            circular
            src={imageLoaded ? formatImageUrl(images![0]?.largeImageOnlineURL) : Placeholder}
            alt={productName}
            onLoad={handleImageLoaded}
          />
          {imageLoaded && (
            <div id="add-to-cart">
              <Image
                outOfStock={isOutOfStock}
                src={ToCart}
                width="50px"
                alt={productName}
                onClick={(event) => (isOutOfStock ? null : handleClick(event))}
              />
              {totalQuantity > 0 && <span id="total-qty">{totalQuantity as number}</span>}
            </div>
          )}
          {isOutOfStock && (
            <Span
              id="out-of-stock"
              textAlign="center"
              background="#ff54547e"
              fontSize="1rem"
              color="#fc0101"
            >
              Out of stock
            </Span>
          )}
        </div>
        <Flex
          direction="column"
          width="100%"
          justifyContent="space-evenly"
          padding="0.3rem 0.5rem"
          height="35%"
          bg="transparent"
          gap="0.2rem"
        >
          <Span fontSize="0.8rem" width="100%">
            {productName.length > 55 ? productName.slice(0, 55) + "..." : productName}
          </Span>
          <Flex
            width="100%"
            alignItems="flex-start"
            justifyContent="space-between"
            direction="column"
          >
            <Span fontWeight="900" fontSize="0.9rem">
              {inventoryTypeProp === "VARIATION"
                ? `${formatPrice(minVarPrice)} - ${formatPrice(maxVarPrice)}`
                : inventoryTypeProp === "PIECES_AND_PACK"
                ? `${formatPrice(pieces)} / ${formatPrice(pack)}`
                : formatPrice(Number(price! ?? nonTrackablePrice))}
            </Span>
          </Flex>
        </Flex>
      </CardWrapper>
    </Container>
  );
};

export default ProductCard;
