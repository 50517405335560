import { Colors } from "../../GlobalStyles/theme";
import { RotatingLines } from "react-loader-spinner";

const { primaryColor } = Colors;

export const Loader = ({ width }: { width: string }) => {
	return (
		<RotatingLines
			strokeColor={primaryColor}
			strokeWidth="1"
			animationDuration="0.75"
			width={width}
			visible={true}
		/>
	);
};
