import styled from "styled-components";
import { Colors, FontSizes } from "./theme";

const { primaryColor, white, lightGrey, blackLight, lightBlue, buttonHover } = Colors;
const { detailsFontSize } = FontSizes;

interface TextProps {
	fontSize?: string;
	color?: string;
	fontWeight?: string;
	margin?: string;
	noWrap?: boolean;
	width?: string;
	alignItems?: string;
	justifyContent?: string;
	flexDirection?: string;
	alignSelf?: string;
	textAlign?: string;
}

interface HrProps {
	opacity?: number;
}

export const Text = styled.p<TextProps>`
	display: flex;
	flex-direction: ${({ flexDirection }) => flexDirection ?? "row"};
	font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
	color: ${({ color }) => (color ? color : blackLight)};
	font-weight: ${({ fontWeight }) => fontWeight};
	margin: ${({ margin }) => margin};
	max-width: ${({ width }) => width};
	justify-content: ${({ justifyContent }) => justifyContent};
	align-items: ${({ alignItems }) => alignItems};
	word-wrap: break-word;
	align-self: ${({ alignSelf }) => alignSelf || "start"};
	text-align: ${({ textAlign }) => textAlign || "unset"};

	${({ noWrap }) =>
		noWrap &&
		`
    width: 90%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}

	span {
		margin-right: 3px;
	}
`;

interface SpanProps {
	fontSize?: string;
	color?: string;
	background?: string;
	fontWeight?: string;
	margin?: string;
	width?: string;
	noWrap?: boolean;
	textAlign?: string;
	status?: boolean;
}

export const Span = styled.span<SpanProps>`
	font-size: ${({ fontSize }) => (fontSize ? fontSize : "inherit")};
	color: ${({ color }) => (color ? color : "inherit")};
	background: ${({ background }) => background || "transparent"};
	font-weight: ${({ fontWeight }) => fontWeight};
	margin: ${({ margin }) => margin};
	width: ${({ width }) => width ?? "unset"};
	text-align: ${({ textAlign }) => textAlign ?? "unset"};
	text-overflow: auto;
	word-wrap: break-word;

	${({ noWrap }) =>
		noWrap &&
		`
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `};

	${({ status }) =>
		status &&
		`
    border-radius: 5px;
	padding: 4px 8px;
  `}
`;

interface ButtonProps {
	backgroundColor?: string;
	width?: string;
	height?: string;
	borderRadius?: string;
	borderColor?: string;
	borderSize?: string;
	padding?: string;
	margin?: string;
	color?: string;
	disabled?: boolean;
	alignItems?: string;
	justifyContents?: string;
	selected?: boolean;
	fontSize?: string;
	display?: string;
	minHeight?: string;
	border?: string;
	zIndex?: number;
	maxWidth?: string;
	minWidth?: string;
}

export const Button = styled.button<ButtonProps>`
	text-decoration: none;
	font-style: normal;
	font-weight: normal;
	font-size: ${({ fontSize }) => fontSize ?? detailsFontSize};
	line-height: 16px;
	color: ${({ color }) => color ?? white};
	background-color: ${({ backgroundColor, disabled }): string =>
		disabled ? lightGrey : backgroundColor ?? primaryColor};
	border: ${({ border }) => (border ? border : "none")};
	box-sizing: border-box;
	border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "0px")};
	padding: ${({ padding }) => (padding ? padding : "12px 20px")};
	width: ${({ width }) => width};
	max-width: ${({ maxWidth }) => maxWidth};
	min-width: ${({ minWidth }) => minWidth};
	height: ${({ height }) => (height ? height : "50px")};
	min-height: ${({ minHeight }) => minHeight ?? "unset"};
	display: ${({ display }) => display ?? "flex"};
	align-items: ${({ alignItems }) => alignItems ?? "center"};
	justify-content: ${({ justifyContents }) => justifyContents ?? "center"};
	text-align: left;
	margin: ${({ margin }) => (margin ? margin : "unset")};
	z-index: ${({ zIndex }) => (zIndex ? zIndex : "unset")};
	cursor: pointer;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	${({ selected }) =>
		selected &&
		`
    background-color: ${buttonHover};
	border-left: 3px solid ${primaryColor};

  `}
	&:hover {
		background-color: ${buttonHover};
	}

	&:disabled {
		background-color: ${buttonHover};
		cursor: not-allowed;
	}
`;

export const CountButton = styled.button<ButtonProps>`
	text-decoration: none;
	font-size: ${({ fontSize }) => fontSize ?? detailsFontSize};
	color: ${({ color }) => color ?? white};
	background-color: ${({ backgroundColor }) => backgroundColor ?? primaryColor};
	box-sizing: border-box;
	border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "0px")};
	padding: ${({ padding }) => (padding ? padding : "12px 20px")};
	width: ${({ width }) => width};
	height: ${({ height }) => (height ? height : "50px")};
	display: ${({ display }) => display ?? "flex"};
	align-items: ${({ alignItems }) => alignItems ?? "center"};
	justify-content: ${({ justifyContents }) => justifyContents ?? "center"};
	margin: ${({ margin }) => (margin ? margin : "unset")};
	border: ${({ border }) => (border ? border : "none")};
	cursor: pointer;

	#name {
		margin-left: 0.5rem;
		font-size: 0.9rem;
	}

	&:disabled {
		background-color: ${buttonHover};
		cursor: not-allowed;
	}
`;

interface FlexProps {
	justifyContent?: string;
	alignItems?: string;
	alignSelf?: string;
	width?: string;
	height?: string;
	direction?: string;
	borderRadius?: string;
	boxShadow?: boolean;
	padding?: string;
	margin?: string;
	border?: boolean;
	bg?: string;
	justifySelf?: string;
	gap?: string;
	order?: string;
	maxHeight?: string;
	maxWidth?: string;
	overflowY?: string;
	flexWrap?: string;
	cursor?: string;
}

export const Flex = styled.div<FlexProps>`
	display: flex;
	justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : "flex-start")};
	align-items: ${({ alignItems }) => (alignItems ? alignItems : "flex-start")};
	width: ${({ width }) => width ?? "100%"};
	height: ${({ height }) => height ?? "unset"};
	max-height: ${({ maxHeight }) => maxHeight ?? "unset"};
	max-width: ${({ maxWidth }) => maxWidth ?? "unset"};
	background-color: ${({ bg }) => (bg ? bg : "#fff")};
	padding: ${({ padding }) => padding ?? "0px"};
	margin: ${({ margin }) => margin ?? "0px"};
	flex-direction: ${({ direction }) => (direction ? direction : "row")};
	flex-wrap: ${({ flexWrap }) => flexWrap ?? "unset"};
	align-self: ${({ alignSelf }) => alignSelf ?? "unset"};
	justify-self: ${({ justifySelf }) => justifySelf ?? "unset"};
	border-radius: ${({ borderRadius }) => borderRadius ?? "unset"};
	box-shadow: ${({ boxShadow }) => (boxShadow ? "0px 4px 20px rgba(24, 88, 177, 0.12)" : "none")};
	border: ${({ border }) => (border ? `1px solid ${lightBlue}` : "none")};
	gap: ${({ gap }) => (gap ? gap : "none")};
	overflow-y: ${({ overflowY }) => (overflowY ? overflowY : "unset")};
	cursor: ${({ cursor }) => cursor ?? "unset"};

	.close {
		background: #eee;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		padding: 10px;
	}
`;

interface InputProps {
	background?: string;
	placeholderColor?: string;
	border?: string;
	padding?: string;
	borderRadius?: string;
	height?: string;
}

export const Input = styled.input<InputProps>`
	height: ${({ height }) => height || "30px"};
	width: 100%;
	border: ${({ border }) => border || "none"};
	background: ${({ background }) => background || "transparent"};
	border-radius: ${({ borderRadius }) => borderRadius || "1rem"};
	font-size: 0.9rem;
	margin: 0;
	padding: ${({ padding }) => padding || "0px"};
	outline: none;
	&::placeholder {
		color: ${lightBlue};
		font-size: 0.8rem;
		opacity: 0.6;
	}
	&:focus {
		outline: none;
	}
`;
export const Label = styled.label<InputProps>`
	background: ${({ background }) => background || "transparent"};
	font-size: 0.7rem;
	width: fit-content;
	position: absolute;
	top: -8px;
	left: 1rem;
	color: ${({ color }) => color || "#000"};
	padding: ${({ padding }) => padding || "0px"};
`;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	bottom: 0;
	width: calc(100% - 2rem);
	align-self: center;
	margin-top: 2rem;
`;

export const Hr = styled.hr<HrProps>`
	border-top: 1px solid ${lightBlue};
	opacity: ${({ opacity }) => (opacity ? opacity : 0.3)};
	width: 100%;
`;

interface ICheckBox {
	checked: boolean;
}
export const CheckBox = styled.div`
	border: 1px solid ${(props: ICheckBox) => (props.checked ? "#FFA412" : "#8196B3")};
	height: 20px;
	width: 20px;
	min-width: 20px;
	min-height: 20px;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background: ${(props: ICheckBox) => (props.checked ? "#FFA412" : "transparent")};

	img {
		display: ${(props: ICheckBox) => (props.checked ? "block" : "none")};
		width: 11px;
		height: 11px;
	}
`;
