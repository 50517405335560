import { Dot, DotContainer, ProductImageCard, Wrapper } from "./Carousel.styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Image } from "../../pages/home/home.styles";
import { useState } from "react";
import { Images } from "../../interfaces/inventory.interface";
import { formatImageUrl } from "../../utils/formatImage.utils";

interface ICarousel {
	images?: [Images];
}

const Carousel: React.FC<ICarousel> = ({ images }) => {
	const [sliderIndex, setSliderIndex] = useState(0);

	const settings = {
		beforeChange: (next: number) => {
			setSliderIndex(next);
		},
		dots: false,
		infinite: true,
		speed: 500,
		autoplaySpeed: 10000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		arrows: false,
		fade: true,
		fadeSpeed: 1000,
	};

	return (
		<Wrapper>
			<ProductImageCard>
				{images && images?.length > 0 ? (
					<Slider {...settings}>
						{images?.map((image, i) => {
							return (
								<Image
									key={i}
									src={formatImageUrl(image?.largeImageOnlineURL)}
									width="200px"
									height="200px"
									circular
								/>
							);
						})}
					</Slider>
				) : (
					<Image src={formatImageUrl()} width="200px" height="200px" circular />
				)}
				<DotContainer>
					{images?.map((_, i) => (
						<Dot key={i} position={i} slideIndex={sliderIndex}></Dot>
					))}
				</DotContainer>
			</ProductImageCard>
		</Wrapper>
	);
};

export default Carousel;
