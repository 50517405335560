import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client";

const location = globalThis.location;
const isProduction = ["order.gettimart.com"].includes(location?.host);

const mainApiUrl = isProduction
	? "https://api.gettimart.com/graphql"
	: "https://staging-api.gettimart.com/graphql";

const httpLink = createHttpLink({
	uri: mainApiUrl,
	credentials: "same-origin",
});

const self = globalThis as any;

export default new ApolloClient<NormalizedCacheObject>({
	link: httpLink,
	cache: new InMemoryCache().restore(self.__APOLLO_STATE__ || {}),
	ssrMode: true,
});
