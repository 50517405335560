import { Flex, Span } from "../../GlobalStyles/CustomizableGlobal.style";
import { Colors } from "../../GlobalStyles/theme";
import { Divider, Status, Wrapper } from "./order.styles";
import Nav from "../../Components/Nav";
import formatPrice from "../../utils/formatPrice.utils";
import Pending from "../../assets/pending.svg";
import PendingColor from "../../assets/pendingColor.svg";
import Processing from "../../assets/processing.svg";
import ProcessingColor from "../../assets/processingColor.svg";
import Onitsway from "../../assets/onItsway.svg";
import OnitswayColor from "../../assets/onItswayColor.svg";
import Delivered from "../../assets/delivered.svg";
import DeliveredColor from "../../assets/deliveredColor.svg";
import { IOrderItem } from "../../interfaces/orders.interface";
import { Image } from "../home/home.styles";
import { formatImageUrl } from "../../utils/formatImage.utils";
import moment from "moment";
import { useLocation } from "react-router-dom";

interface IOrderCard {
	item?: IOrderItem;
	orderDetails: Function;
	orderNumber?: string | null;
}
const Order: React.FC<IOrderCard> = ({ item, orderDetails, orderNumber }) => {
	const { lightBlue, grey, primaryGrey } = Colors;
	const query = new URLSearchParams(useLocation().search);
	const itemParam = query.get("item");

	const getStatus = () => {
		const x = [
			{ name: "PENDING", name2: "", name3: "", icon: Pending, iconActive: PendingColor },
			{
				name: "PROCESSING",
				name2: "PROCESSED",
				name3: "",
				icon: Processing,
				iconActive: ProcessingColor,
			},
			{ name: "ON_DELIVERING", name2: "", name3: "", icon: Onitsway, iconActive: OnitswayColor },
			{
				name: "DELIVERED",
				name2: "CANCELLED",
				name3: "COMPLETED",
				icon: Delivered,
				iconActive: DeliveredColor,
			},
		];

		const step = item?.Steps?.stepName;

		return (
			<Flex>
				<Flex
					width="100%"
					margin="1rem 0"
					alignItems="center"
					justifyContent="space-between"
					style={{ position: "relative" }}>
					{Array.from(x).map(({ name, name2, name3 }) => {
						const { icon: image, iconActive: imageActive } =
							x.find((img) => (img.name || img.name2) === (name || name2)) || {};
						return (
							<Flex
								key={`${name}-${name2}-${name3}`}
								alignItems="center"
								justifyContent="center">
								<Wrapper active={name === step || name2 === step || name3 === step}>
									<img
										src={
											name === step || name2 === step || name3 === step
												? imageActive
												: image
										}
										alt=""
									/>
								</Wrapper>
								<Status active={name === step || name2 === step || name3 === step}>
									{name === "ON_DELIVERING" || name2 === "ON_DELIVERING"
										? "ON ITS WAY"
										: name || name2}
								</Status>
							</Flex>
						);
					})}
					<Divider />
				</Flex>
			</Flex>
		);
	};

	return (
		<Flex direction="column" padding="1rem" width="100%" maxWidth="576px">
			<Nav
				route={`/order-items?id=${itemParam}&ordernumber=${orderNumber}`}
				title={item?.inventoryName}
			/>
			<Flex alignItems="center" justifyContent="space-between" margin="2rem 0 0 0">
				<Span color={grey} fontSize="0.8rem">
					Time of order
				</Span>
				<Span color={grey} fontSize="0.8rem">
					{moment(orderDetails(item?.orderId).time).format("MMMM Do YYYY, h:mm a")}
				</Span>
			</Flex>
			<Span color={lightBlue} fontSize="1rem" margin="1.5rem 0 0 0">
				Order Details
			</Span>

			<Flex>{getStatus()}</Flex>

			<Flex
				alignItems="center"
				justifyContent="flex-start"
				width="100%"
				margin="4rem 0 0 0"
				gap="1rem"
				height="60px">
				<Image
					width="100px"
					circular
					src={formatImageUrl(item?.Inventory?.Images[0]?.largeImageOnlineURL)}
					alt="product image"
				/>
				<Flex alignItems="flex-start" justifyContent="space-between" direction="column" height="50px">
					<Span fontSize="1.2rem" fontWeight="700" color={primaryGrey}>
						{item?.inventoryName}
					</Span>
					<Span fontSize="1rem" fontWeight="700" color={primaryGrey}>
						{formatPrice(item?.amount)}
					</Span>
				</Flex>
			</Flex>
			<Flex width="100%" direction="column" margin="2rem 0 0 0" gap="1rem">
				<Flex width="100%" justifyContent="space-between" alignItems="center">
					<Span color={grey} fontSize="1rem">
						Quantity
					</Span>
					<Span color={primaryGrey} fontSize="1.1rem">
						X{item?.quantity}
					</Span>
				</Flex>

				<Flex width="100%" justifyContent="space-between" alignItems="flex-start" gap="1rem">
					<Span color={grey} fontSize="1rem">
						Comment:
					</Span>
					<Span color={primaryGrey} fontSize="1rem">
						{orderDetails(item?.orderId).comment}
					</Span>
				</Flex>

				<Flex width="100%" justifyContent="space-between" alignItems="center">
					<Span color={primaryGrey} fontSize="1.2rem" fontWeight="700">
						Order Total
					</Span>
					<Span color={primaryGrey} fontSize="1.2rem" fontWeight="700">
						{formatPrice(item?.amount)}
					</Span>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default Order;
