import styled from "styled-components";
import { Colors } from "../../GlobalStyles/theme";
const { primaryColor, inActiveTab } = Colors;

interface PageProps {
	page?: number;
	marginBottom?: string;
	position?: string;
}

export const Page = styled.div<PageProps>`
	display: flex;
	width: 100%;
	max-width: 576px;
	margin: auto;
	margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : "16px")};
	justify-content: center;
	align-items: center;
	margin-top: 1rem;
	gap: 1px;
	position: ${({ position }) => (position ? position : "fixed")};

	${({ position }) =>
		position === "fixed" &&
		`
	bottom: 3rem;
	
	`}

	.prev {
		height: 30px;
		padding: 0 20px;
		border: none;
		background-color: ${primaryColor};
		color: #fff;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		cursor: pointer;

		&:disabled {
			color: #cccccc;
			cursor: unset;
			background-color: ${inActiveTab};
		}
	}
	.next {
		height: 30px;
		padding: 0 20px;
		background-color: ${primaryColor};
		border: none;
		color: #fff;
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;
		cursor: pointer;

		&:disabled {
			color: #cccccc;
			cursor: unset;
			background-color: ${inActiveTab};
		}
	}
`;
