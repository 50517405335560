import { CountButton, Flex, Span, Text } from "../../GlobalStyles/CustomizableGlobal.style";
import { Colors } from "../../GlobalStyles/theme";
import { Image } from "../../pages/home/home.styles";
import { Container, Wrapper } from "./AddToCart.styles";
import Close from "../../assets/close.svg";
import { useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../store/redux-hook";
import { addToCart, decQty, incQty } from "../../store/slices/cartSlice";
import formatPrice from "../../utils/formatPrice.utils";
import Carousel from "../Carosel";
import { Inventory } from "../../interfaces/inventory.interface";
import { getInventoryPrice, getNonTrackablePrice } from "../../utils/inventory.utils";
import { BuyType, ICart } from "../../interfaces/cart.interface";
import Variation from "./Variation";
import PiecesAndPack from "./PiecesAndPack";
import { toggleSnackbarOpen } from "../../store/slices/snackbarSlice";
interface IAddToCart {
	inventory?: Inventory;
	setPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddToCart: React.FC<IAddToCart> = ({ inventory, setPopup }) => {
	const dispatch = useAppDispatch();
	const { offWhite } = Colors;
	const [quantity, setQuantity] = useState(1);

	const { cart } = useAppSelector((state) => state.cart);
	const cartItem = cart.find((item) => item.inventory?.inventoryId === inventory?.inventoryId);

	const inventoryTypeProp = inventory?.inventoryType;
	const inventoryPrice = inventory?.InventoryPrice;
	const price = getInventoryPrice(inventoryPrice, inventoryTypeProp)?.sellingPrice!;
	const trackable = inventory?.trackable;
	const nonTrackablePrice = useMemo(
		() => getNonTrackablePrice(inventory, inventoryTypeProp)?.sellingPrice,
		[inventoryTypeProp, inventory]
	);

	const item: ICart = {
		inventory,
		quantity,
		price: price ?? nonTrackablePrice,
		inventoryType: inventoryTypeProp,
	};

	const inCart = cartItem?.inventory?.inventoryId === item?.inventory?.inventoryId;

	const handleAddToCart = () => {
		dispatch(addToCart(item));
		setPopup(false);
		dispatch(
			toggleSnackbarOpen({
				message: `${item.inventory?.inventoryName} added to cart!`,
				color: "SUCCESS",
			})
		);
	};

	const handleQuantity = (type: string, buyType?: BuyType) => {
		if (type === "inc") {
			setQuantity(quantity + 1);
			dispatch(incQty({ id: item.inventory?.inventoryId!, buyType }));
		}

		if (type === "dec") {
			setQuantity(Math.max(1, quantity - 1));
			dispatch(decQty({ id: item.inventory?.inventoryId!, buyType }));
		}
	};

	const getTotalPrice = () => {
		if (trackable) {
			return quantity * price;
		} else {
			return quantity * nonTrackablePrice!;
		}
	};
	return (
		<Wrapper>
			<Container>
				<Flex
					alignItems="center"
					justifyContent="space-between"
					padding="1rem 1rem 0 1rem"
					bg="transparent">
					<Text fontWeight="500" fontSize="1.1rem">
						Add to Cart
					</Text>
					<Image
						width="15px"
						src={Close}
						className="close"
						onClick={() => setPopup(false)}
						style={{ cursor: "pointer" }}
					/>
				</Flex>
				<Flex alignItems="center" justifyContent="center">
					<Carousel images={inventory?.Images} />
				</Flex>

				{inventoryTypeProp === "VARIATION" ? (
					<Variation inventory={inventory} setPopup={setPopup} />
				) : inventoryTypeProp === "PIECES_AND_PACK" ? (
					<PiecesAndPack
						inventory={inventory}
						setPopup={setPopup}
						quantity={quantity}
						handleQuantity={handleQuantity}
					/>
				) : (
					<>
						<Flex
							bg={offWhite}
							width="100%"
							height="70px"
							padding="1rem"
							justifyContent="space-between"
							alignItems="center"
							margin="0 0 1rem 0">
							<Flex
								width="100%"
								bg="transparent"
								direction="column"
								justifyContent="space-between"
								alignItems="flex-start">
								<Text fontSize="1.2rem">{inventory?.inventoryName}</Text>
								<Text fontSize="1.2rem" fontWeight="700">
									{formatPrice(price ?? nonTrackablePrice)}
								</Text>
							</Flex>
							<Flex
								bg="transparent"
								justifyContent="space-between"
								alignItems="center"
								width="40%">
								<CountButton
									disabled={inCart ? cartItem?.quantity! <= 1 : quantity <= 1}
									height="35px"
									borderRadius="4px"
									padding="3px"
									width="35px"
									margin="0 5px"
									fontSize="1.5rem"
									onClick={() => handleQuantity("dec")}>
									-
								</CountButton>
								<Span fontWeight="700">{inCart ? cartItem?.quantity : quantity}</Span>
								<CountButton
									disabled={
										trackable &&
										(inCart ? cartItem?.quantity! : quantity) >= inventory?.quantity!
									}
									height="35px"
									borderRadius="4px"
									padding="3px"
									width="35px"
									margin="0 5px"
									fontSize="1.5rem"
									onClick={() => handleQuantity("inc")}>
									+
								</CountButton>
							</Flex>
						</Flex>
						<Flex
							justifyContent="center"
							alignItems="center"
							width="100%"
							padding=" 0 1rem 1rem 1rem">
							<CountButton
								borderRadius="1.2rem"
								width="100%"
								onClick={handleAddToCart}
								disabled={inCart}>
								{inCart ? "Item already in cart -" : "Add"} {formatPrice(getTotalPrice())}
							</CountButton>
						</Flex>
					</>
				)}
			</Container>
			<div className="closeDiv" onClick={() => setPopup(false)}></div>
		</Wrapper>
	);
};

export default AddToCart;
