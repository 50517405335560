import { FunctionComponent, memo } from "react";
import { GetAllShopInventoryCategoryProps } from "../../interfaces/categories.interface";
import { Category, CategoryContainer } from "./Categories.styles";
import Error from "../ErrorComponent";
import { useQuery } from "@apollo/client";
import { Loader } from "../LoaderComponent/LoaderComponent";
import { GET_ALL_SHOP_INVENTORY_CATEGORY_SCHEMA } from "../../schemas/schemas";
import { getShopId } from "../../utils/getIds.utils";
import { Flex } from "../../GlobalStyles/CustomizableGlobal.style";

interface ICategory {
	selectedCategoryId?: string;
	setSelectedCategoryId?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const Categories: FunctionComponent<ICategory> = ({ selectedCategoryId, setSelectedCategoryId }) => {
	const {
		data,
		loading: catLoading,
		error,
	} = useQuery<GetAllShopInventoryCategoryProps>(GET_ALL_SHOP_INVENTORY_CATEGORY_SCHEMA, {
		variables: {
			shopId: getShopId(),
		},
	});

	if (error) return <Error message={error.message} />;
	return (
		<Flex width="100%">
			{catLoading ? (
				<div
					style={{
						height: "30px",
						width: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}>
					<Loader width="20" />
				</div>
			) : (
				<CategoryContainer>
					<Category
						active={selectedCategoryId === undefined}
						onClick={() => setSelectedCategoryId!(undefined)}>
						All
					</Category>
					{data?.getAllShopInventoryCategory?.map((category, i) => (
						<Category
							key={i}
							active={selectedCategoryId === category.inventoryCategoryId}
							onClick={() => setSelectedCategoryId!(category.inventoryCategoryId)}>
							{category.inventorycategoryName}
						</Category>
					))}
				</CategoryContainer>
			)}
		</Flex>
	);
};

export default memo(Categories);
