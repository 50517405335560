const formatPrice = (amount?: number, currency?: string): string | null => {
	if (!amount) {
		return "₦0";
	}
	if (amount === 0) {
		return "₦0";
	}
	const formattedAmount = amount?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
	return `${currency ?? "₦"}${formattedAmount}`;
};

export default formatPrice;
