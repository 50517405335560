import styled from "styled-components";
import { Colors } from "../../GlobalStyles/theme";
const { primaryColor, grey } = Colors;

interface ImageProps {
	width?: string;
	height?: string;
	circular?: boolean;
	border?: boolean;
	outOfStock?: boolean;
	maxWidth?: string;
}

export const Image = styled.img<ImageProps>`
	width: ${({ width }) => width || "20px"};
	max-width: ${({ maxWidth }) => maxWidth ?? "unset"};
	height: ${({ height }) => height};
	max-height: ${({ height }) => height};
	border: ${({ border }) => (border ? "2px solid #9EA8B7" : "none")};
	border-radius: ${({ circular }) => (circular ? "50%" : "none")};
	cursor: ${({ outOfStock }) => (outOfStock ? "not-allowed" : "pointer")};
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	.header {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 100;
		background: white;
	}

	#call {
		display: flex;
		align-items: center;
		gap: 0.5em;
		background: none;
		border: none;
		color: ${primaryColor};
		cursor: pointer;
	}

	.bottom {
		display: flex;
		width: 100%;
		flex-direction: column;
		bottom: 3rem;
		position: fixed;
	}
`;

export const CategoryContainer = styled.div<{ height?: string }>`
	display: flex;
	gap: 10px;
	height: 100%;
	height: ${({ height }) => height ?? "100%"};
	width: auto;
	overflow-x: scroll;
	margin-bottom: 0;
	margin: 0 1rem;

	::-webkit-scrollbar {
		background-color: #f6f8fb;
		height: 3px;
	}

	::-webkit-scrollbar-thumb {
		background-color: #ffbe62;
		border-radius: 4px;
	}
`;
export const Category = styled.p<{ active?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	width: auto;
	padding: 5px 15px;
	margin: 5px 0;
	border-radius: 1rem;
	font-size: 0.8rem;
	white-space: nowrap;
	cursor: pointer;
	color: ${({ active }) => (active ? "white" : grey)};
	background-color: ${({ active }) => (active ? "#130F26" : "#F6F8FB")};
`;
