import { Inventory, Variations } from "./inventory.interface";

export enum BuyType {
	PIECES = "PIECES",
	PACK = "PACK",
}

export interface ICart {
	inventory?: Inventory;
	variant?: Variations;
	inventoryType?: string;
	PiecesAndPackType?: BuyType;
	quantity: number;
	price: number;
	variationName?: string;
	variationId?: string;
}

export interface selectedProductsProps {
	variationId: string;
	variant?: Variations;
	quantity: number;
	price: number;
	variationName: string;
}
