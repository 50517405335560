import React, { FunctionComponent } from "react";
import { ErrorContainer, ErrorMessage } from "./ErrorComponent.style";

interface Props {
	message: string;
}

const Error: FunctionComponent<Props> = ({ message }) => {
	return (
		<ErrorContainer>
			<ErrorMessage>
				{message === "Failed to fetch" ? "Check your network connection" : message}
			</ErrorMessage>
		</ErrorContainer>
	);
};

export default Error;
