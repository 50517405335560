import { createSlice } from "@reduxjs/toolkit";
import { SnackbarType } from "../../Components/snackbar/snackbar";

interface snackbarType {
  toggleSnackbar: boolean;
  snackbarMessage: string | null;
  color?: SnackbarType;
}

const initialState: snackbarType = {
  toggleSnackbar: false,
  snackbarMessage: null,
  color: "DEFAULT",
};

const snackbarStatus = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    toggleSnackbarOpen: (state, { payload }) => {
      state.toggleSnackbar = true;

      let color: SnackbarType = "DEFAULT";
      const incomingMessage = payload.message;
      color = payload.color;

      const message = incomingMessage.includes("https://")
        ? "Error! Check your network connection"
        : incomingMessage;
      state.snackbarMessage = message;
      state.color = color;
    },
    toggleSnackbarClose: (state) => {
      state.toggleSnackbar = false;
      state.snackbarMessage = null;
    },
  },
});

export const { toggleSnackbarOpen, toggleSnackbarClose } = snackbarStatus.actions;
export default snackbarStatus.reducer;
