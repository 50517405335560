import sampleProductImg from "../assets/image-placeholder.svg";

export const formatImageUrl = (image?: string): string => {
	if (!image) {
		return sampleProductImg;
	}

	return image?.includes("http") ? image : `https://cloud.quickshop.com.ng${image}`;
};

export const formatName = (name?: string) => {
	if (!name) {
		return "";
	}

	const words = name.toLowerCase().split(" ");
	for (let i = 0; i < words.length; i++) {
		words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
	}

	return words.join(" ");
};
