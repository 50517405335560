import { Flex, Span } from "../../GlobalStyles/CustomizableGlobal.style";
import { Colors } from "../../GlobalStyles/theme";
import { Wrapper } from "./BottomNav.styles";
import MealsGrey from "../../assets/meals-grey.svg";
import MealsColor from "../../assets/meals-color.svg";
import CartGrey from "../../assets/cart-grey.svg";
import CartColor from "../../assets/cart-color.svg";
import OrdersGrey from "../../assets/orders-grey.svg";
import OrdersColor from "../../assets/orders-color.svg";
import { Image } from "../../pages/home/home.styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/redux-hook";
const { primaryColor, grey } = Colors;

const BottomNavbar = () => {
	const { cart } = useAppSelector((state) => state.cart);

	const navigate = useNavigate();
	const location = useLocation().pathname;
	const buttons = [
		{ name: "Home", iconGrey: MealsGrey, iconColor: MealsColor, link: "/" },
		{ name: "Cart", iconGrey: CartGrey, iconColor: CartColor, link: "/cart" },
		{ name: "Orders", iconGrey: OrdersGrey, iconColor: OrdersColor, link: "/orders" },
	];

	return (
		<Wrapper>
			{buttons.map((button, i) => {
				return (
					<Flex
						alignItems="center"
						justifyContent="center"
						gap="10px"
						key={i}
						style={{ cursor: "pointer" }}
						onClick={() => {
							navigate(`${button.link}`);
						}}>
						{button.name === "Cart" && <span className="span">{cart.length}</span>}
						<Image
							width="20px"
							src={location === button.link ? button.iconColor : button.iconGrey}
						/>
						<Span fontSize="0.8rem" color={location === button.link ? primaryColor : grey}>
							{button.name}
						</Span>
					</Flex>
				);
			})}
		</Wrapper>
	);
};

export default BottomNavbar;
