import { Helmet } from "react-helmet";
import { formatName } from "../../utils/formatImage.utils";
import { useQuery } from "@apollo/client";
import { GET_SHOP_TYPE } from "../../interfaces/shop.interface";
import { GET_SHOP_SCHEMA } from "../../schemas/schemas";
import { getShopId } from "../../utils/getIds.utils";

function NewHelmet() {
  const { data } = useQuery<GET_SHOP_TYPE>(GET_SHOP_SCHEMA, {
    fetchPolicy: "network-only",
    variables: {
      shopId: getShopId(),
      shopStatus: "KITCHEN_PUBLISHED",
    },
  });

  return (
    <Helmet>
      <title>{formatName(data?.getShop?.shopName)} - Timart</title>
    </Helmet>
  );
}

export default NewHelmet;
