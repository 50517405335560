import styled from "styled-components";
import { Colors } from "../../GlobalStyles/theme";
const { primaryColor, white } = Colors;

export const Container = styled.div`
	display: flex;
	flex-direction: column;

	.cart {
		max-height: calc(100vh - 350px);
		overflow-y: scroll;
		padding-right: 0.5rem;
	}

	.back-home {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		width: 150px;
		padding: 4px 15px;
		border-radius: 12px;
		background-color: ${primaryColor};
		color: ${white};
		font-size: 1.2rem;
	}
`;
