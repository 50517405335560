import { FunctionComponent, useEffect, useRef, useMemo } from "react";
import {
  GET_ALLSHOP_OFFLINE_INVENTORIES_TYPE,
  Inventory,
} from "../../interfaces/inventory.interface";
import ProductCard from "../ProductCard";
import { Grid, ProductsContainer } from "./Products.styles";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import AddToCart from "../AddToCart";
import Error from "../ErrorComponent";
import { GET_ALLSHOP_OFFLINE_INVENTORIES_SCHEMA } from "../../schemas/schemas";
import No_Product_Image from "../../assets/noProducts.svg";
import { Image } from "../../pages/home/home.styles";
import { Flex, Input, Span } from "../../GlobalStyles/CustomizableGlobal.style";
import { Colors } from "../../GlobalStyles/theme";
import { Loader } from "../LoaderComponent/LoaderComponent";
import Categories from "../Categories";
import SearchIcon from "../../../src/assets/searchIcon.svg";

interface IProps {
  isAvailableProducts: boolean;
  searchString: string;
  setSearchString: Function;
}

const Products: FunctionComponent<IProps> = ({
  isAvailableProducts,
  searchString,
  setSearchString,
}) => {
  const [selectedInventoryId, setSelectedInventoryId] = useState<string | undefined>(undefined);
  const [selectedInventory, setSelectedInventory] = useState<Inventory>();
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | undefined>(undefined);
  const [inventories, setInventories] = useState<Inventory[]>([]);
  const [popup, setPopup] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(0);

  const { blackLight } = Colors;
  const targetRef = useRef<HTMLDivElement | null>(null);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const { shopId } = params;

  const incrementPageNumber = () => {
    setPage((prev) => {
      setPreviousPage(prev);

      return prev + 1;
    });
  };

  const { loading, error, data, refetch } = useQuery<GET_ALLSHOP_OFFLINE_INVENTORIES_TYPE>(
    GET_ALLSHOP_OFFLINE_INVENTORIES_SCHEMA,
    {
      fetchPolicy: "network-only",
      variables: {
        shopId: shopId || null,
        page,
        categoryId: selectedCategoryId,
        filterByInStock: isAvailableProducts,
        limit: 10,
        searchString,
      },
    },
  );

  /* `options` is a memoized object that contains the options for the `IntersectionObserver` used in the
	component. The `useMemo` hook is used to memoize the object so that it is only created once and not
	recreated on every render. The object contains two properties: `root` and `threshold`. `root` is set
	to the `#grid` element in the DOM, which is the root element for the `IntersectionObserver`.
	`threshold` is set to 0.9, which means that the `IntersectionObserver` will trigger when the target
	element is 90% visible in the root element. */
  const options = useMemo(
    () => ({
      root: document.querySelector("#grid"),
      threshold: 0.9,
    }),
    [],
  );

  /* A hook setting up an IntersectionObserver to detect when the target element (which
	is a div with a ref of `targetRef`) is intersecting with the root element (which is a div with an id
	of "grid"). When the target element is intersecting with the root element and there are more
	inventories to load, it calls the `getData` function to fetch more data and updates the
	`inventories` state with the new data. It also updates the `page` state to keep track of the current
	page number. The hook returns a cleanup function that disconnects the observer when the component
	unmounts. The hook depends on the `data`, `getData`, `inventories.length`, and `options` variables. */
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      const hasMore = data?.getAllShopOfflineInventories?.total! > inventories.length;

      if (entry.isIntersecting && hasMore && !loading) {
        incrementPageNumber();
      }
    }, options);

    const target = targetRef.current;
    target && observer.observe(target);

    return () => observer.disconnect();
  }, [data, refetch, inventories.length, loading, options]);

  useEffect(() => {
    if (previousPage === page) {
      return;
    }

    if (data) {
      const inventoryData = data?.getAllShopOfflineInventories.inventories;
      setInventories(page === 1 ? inventoryData : (prevInv) => [...prevInv, ...inventoryData]);
    }
  }, [
    page,
    selectedCategoryId,
    isAvailableProducts,
    searchString,
    refetch,
    previousPage,
    shopId,
    data,
  ]);

  useEffect(() => {
    setPage(1);
    setPreviousPage(0);
    if (selectedCategoryId || searchString || !isAvailableProducts) setInventories([]);
  }, [selectedCategoryId, isAvailableProducts, searchString]);

  useEffect(() => {
    const selected = inventories.find((inventory) => inventory.inventoryId === selectedInventoryId);
    setSelectedInventory(selected);
  }, [selectedInventoryId, inventories]);

  const noProducts = () => {
    return (
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        bg="transparent"
        gap="1rem"
        margin="2rem 0 0 0"
        className="no-product"
        alignSelf="center"
      >
        <Image width="50px" src={No_Product_Image} />
        <Span textAlign="center" fontWeight="700" color={blackLight}>
          No Products in this category
        </Span>
      </Flex>
    );
  };

  if (error) return <Error message={error.message} />;

  return (
    <ProductsContainer>
      <Grid id="grid">
        <div id="filter">
          <Flex
            borderRadius="0.8rem"
            padding="5px 5px 5px 1rem"
            height="40px"
            margin="0"
            border={true}
            alignItems="center"
          >
            <Image width="20px" src={SearchIcon} alt="" style={{ marginRight: "0.5rem" }} />
            <Input
              placeholder="Search meals. e.g Rice and stew"
              onChange={(e) => setSearchString(e.target.value)}
            />
          </Flex>
          <Categories
            setSelectedCategoryId={setSelectedCategoryId}
            selectedCategoryId={selectedCategoryId}
          />
        </div>
        {inventories.length < 1 && !loading
          ? noProducts()
          : inventories?.map((inventory) => (
              <div
                ref={targetRef}
                className="products"
                key={inventory.inventoryId}
                onClick={() => setSelectedInventory(inventory)}
              >
                <ProductCard
                  inventory={inventory}
                  setPopup={setPopup}
                  setSelectedInventoryId={setSelectedInventoryId}
                />
              </div>
            ))}
        {loading && (
          <div className="loader">
            <Loader width="70" />
          </div>
        )}
      </Grid>

      {popup && <AddToCart inventory={selectedInventory} setPopup={setPopup} />}
    </ProductsContainer>
  );
};

export default Products;
