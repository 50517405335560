import { Route, BrowserRouter, Routes } from "react-router-dom";
import Cart from "./pages/cart";
import Home from "./pages/home";
import Orders from "./pages/orders";
import { v4 as uuid } from "uuid";
import ConfirmOrder from "./pages/orders/confirmOrder";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import Snackbar from "./Components/snackbar/snackbar";
import OrderItems from "./pages/orderItems";
import NewHelmet from "./Components/Helmet/helmet";

const googleTagTrackingId = "GTM-XCFD0XL3D9";
const tagManagerArgs = {
  gtmId: googleTagTrackingId,
};

function setUniqueId() {
  const uniqueId = localStorage.getItem("orderUniqueId");
  if (uniqueId) {
    return;
  } else {
    const id = Array.from({ length: 4 }, () => uuid()).join("");
    localStorage.setItem("orderUniqueId", id);
  }
}
setUniqueId();

function App() {
  useEffect(() => {
    if ((window as any)?.location?.host?.match(/^order\.gettimart\.com$/)) {
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  return (
    <div>
      <NewHelmet />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/order-items" element={<OrderItems />} />
          <Route path="/confirm-order" element={<ConfirmOrder />} />
        </Routes>
      </BrowserRouter>
      <Snackbar timeout={5000} />
    </div>
  );
}

export default App;
