import { FunctionComponent, ReactElement, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Colors } from "../../GlobalStyles/theme";
import { useAppDispatch, useAppSelector } from "../../store/redux-hook";
import { toggleSnackbarClose } from "../../store/slices/snackbarSlice";

const fadein = keyframes`
  0% {
    left: 0rem;
    opacity: 0;
  }

  100% {
    left: 1rem;
    opacity: 1;
  }
`;

const fadeout = keyframes`
  0% {
    left: 1rem;
    opacity: 1;
  }

  100% {
    left: 0;
    opacity: 0;
  }
`;

export type SnackbarType = "SUCCESS" | "DANGER" | "DEFAULT" | "INFO";

interface ContainerProps {
  color?: SnackbarType;
  time: any;
}

const getSnackBarColor = (color: ContainerProps["color"] = "DEFAULT") => {
  switch (color) {
    case "DANGER":
      return "#E53E3E";
    case "INFO":
      return "#ffb700";
    case "SUCCESS":
      return "#38A169";
    case "DEFAULT":
    default:
      return Colors.primaryColor;
  }
};

const Container = styled.div<ContainerProps>`
  position: fixed;
  z-index: 30000;
  left: 1rem;
  top: 1rem;
  max-width: 576px;
  width: auto;
  margin: 0 auto;
  align-self: center;
  height: 4rem;
  padding: 0.625rem 1rem;
  border-radius: 5px;
  border: none;
  background-color: ${(props) => getSnackBarColor(props.color)};
  color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadein} 0.5s, ${fadeout} 0.5s ${(props: { time: any }) => props.time};
`;
const Snackbar: FunctionComponent<{ timeout: any }> = ({
  timeout,
}): ReactElement<any, any> | null => {
  const dispatch = useAppDispatch();

  // select the UI states from the redux store
  const {
    toggleSnackbar: SHOW,
    snackbarMessage: MESSAGE,
    color,
  } = useAppSelector((state) => state.snackbar);

  // convert the timeout prop to pass into the styled component
  const TIME = (timeout - 500) / 1000 + "s";

  let TIMER: any;
  const handleTimeout = () => {
    TIMER = setTimeout(() => {
      dispatch(toggleSnackbarClose());
    }, timeout);
  };

  useEffect(() => {
    if (SHOW) {
      handleTimeout();
    }
    return () => {
      clearTimeout(TIMER);
    };
  }, [SHOW, TIMER]);

  return (
    <>
      {SHOW && (
        <Container color={color} time={TIME}>
          <p>{MESSAGE}</p>
        </Container>
      )}
    </>
  );
};

export default Snackbar;
