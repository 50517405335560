import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { BuyType, ICart } from "../../interfaces/cart.interface";

const initialState = {
	cart: [] as ICart[],
};

const cartSlice = createSlice({
	name: "cart",
	initialState,
	reducers: {
		addToCart: (state, action: PayloadAction<ICart>) => {
			const index = current(state.cart).findIndex((item) => {
				if (action.payload.inventoryType === "PIECES_AND_PACK") {
					return (
						item.inventory?.inventoryId === action.payload.inventory?.inventoryId &&
						item.PiecesAndPackType === action.payload.PiecesAndPackType
					);
				}
				return item.inventory?.inventoryId === action.payload.inventory?.inventoryId;
			});

			index >= 0 ? (state.cart[index].quantity += 1) : state.cart.unshift(action.payload);
		},

		addToCartVariation: (state, action: PayloadAction<ICart[]>) => {
			action.payload.forEach((item) => {
				const index = current(state.cart).findIndex((cartItem) => {
					return cartItem.variationId === item.variationId;
				});
				index >= 0 ? (state.cart[index].quantity += 1) : state.cart.unshift(item);
			});
		},

		removeFromCart: (state, action: PayloadAction<{ id?: string; buyType?: BuyType }>) => {
			const index = state.cart.findIndex((item) => {
				let tempId = "";
				if (item.inventoryType === "PIECES_AND_PACK") {
					return (
						item.inventory?.inventoryId === action.payload.id &&
						item.PiecesAndPackType === action.payload.buyType
					);
				} else if (item.inventoryType === "VARIATION") tempId = item.variationId!;
				else tempId = item.inventory?.inventoryId!;

				return tempId === action.payload.id;
			});

			if (index !== -1) {
				state.cart.splice(index, 1);
			}
		},

		clearCart: (state) => {
			state.cart = [];
		},

		incQty: (state, action: PayloadAction<{ id?: string; buyType?: BuyType }>) => {
			const index = current(state.cart).findIndex((item) => {
				let tempId = "";
				if (item.inventoryType === "PIECES_AND_PACK") {
					return (
						item.inventory?.inventoryId === action.payload.id &&
						item.PiecesAndPackType === action.payload.buyType
					);
				} else if (item.inventoryType === "VARIATION") tempId = item.variationId!;
				else tempId = item.inventory?.inventoryId!;

				return tempId === action.payload.id;
			});

			if (index >= 0) state.cart[index].quantity += 1;
		},

		decQty: (state, action: PayloadAction<{ id?: string; buyType?: BuyType }>) => {
			const index = current(state.cart).findIndex((item) => {
				let tempId = "";
				if (item.inventoryType === "PIECES_AND_PACK") {
					return (
						item.inventory?.inventoryId === action.payload.id &&
						item.PiecesAndPackType === action.payload.buyType
					);
				} else if (item.inventoryType === "VARIATION") tempId = item.variationId!;
				else tempId = item.inventory?.inventoryId!;

				return tempId === action.payload.id;
			});

			if (index >= 0) state.cart[index].quantity -= 1;
		},
	},
});

export const { addToCart, removeFromCart, incQty, decQty, clearCart, addToCartVariation } = cartSlice.actions;
export default cartSlice.reducer;
