import styled from "styled-components";
import { Colors } from "../../GlobalStyles/theme";

const { white, primaryColor } = Colors;
interface ICheckBox {
	checked: boolean;
	radius?: string;
}

export const Wrapper = styled.div`
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	background-color: #00000095;

	.closeDiv {
		display: flex;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		height: 100vh;
		width: 100vw;
		background-color: transparent;
		z-index: -8;
	}
`;
export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	border-radius: 20px 20px 0 0;
	background-color: ${white};
	position: fixed;
	bottom: 0;
	width: 100%;
	max-width: 576px;
	justify-content: space-between;
`;

export const ProductImageCard = styled.div`
	margin: 0 auto;
	margin-bottom: 80px;
	align-items: center;
	max-width: 350px;
	width: 100%;
	position: relative;
	background: ${white};
	box-shadow: 0px 4px 20px rgba(24, 88, 177, 0.12);
`;

export const ImageSelector = styled.div`
	background: ${white};
	box-shadow: 0px 4px 12px rgba(23, 46, 78, 0.06);
	display: flex;
	border: 1px solid ${primaryColor};
	position: absolute;
`;

export const CheckBox = styled.label`
	padding: 0px;
	margin: 0px;
	border: 2px solid ${(props: ICheckBox) => (props.checked ? "#219653" : "#9EA8B7")};
	height: 16px;
	width: 16px;
	min-width: 16px;
	min-height: 16px;
	border-radius: ${(props: ICheckBox) => props.radius ?? "3px"};
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	span {
		padding: 0px;
		margin: 0px;
		display: block;
		width: 10px;
		height: 10px;
		border-radius: ${(props: ICheckBox) => props.radius ?? "3px"};
		background: ${(props: ICheckBox) => (props.checked ? "#219653" : "transparent")};
	}
`;
