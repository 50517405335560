import styled from "styled-components";
import { Colors } from "../../GlobalStyles/theme";

const { lightBlue, skyBlue, primaryColor, buttonHover, grey } = Colors;

interface IWrapper {
	active?: boolean;
}
interface IStatus {
	active?: boolean;
}

export const Wrapper = styled.div<IWrapper>`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 25px;
	min-height: 25px;
	width: ${({ active }) => (active ? "50px" : "35px")};
	height: ${({ active }) => (active ? "50px" : "35px")};
	background: ${({ active }) => (active ? buttonHover : skyBlue)};
	border: ${({ active }) => (active ? `1px solid ${primaryColor}` : "none")};
	border-radius: 50%;
	padding: 0;
	z-index: 30;

	img {
		width: ${({ active }) => (active ? "25px" : "20px")};
		height: ${({ active }) => (active ? "25px" : "20px")};
	}
`;
export const Divider = styled.hr`
	border: none;
	border-top: 1px dashed ${lightBlue};
	width: auto;
	height: 1px;
	z-index: 3;
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	right: 0;
	left: 0;
	width: 80%;
	margin: 0 auto;
`;
export const Status = styled.span<IStatus>`
	display: flex;
	position: absolute;
	bottom: -2rem;
	margin-top: 2rem;
	text-transform: capitalize;
	font-size: 0.7rem;
	color: ${grey};
	color: ${({ active }) => (active ? primaryColor : grey)};
`;
